.App {
  height: 100vh;
  width: 100%;
  display: grid;
}

.main {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-areas:
    'navbar'
    'body'
    'body';
  grid-template-rows: 60px auto 1fr;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 1s;
}

.title {
  font-size: 3rem;
  font-weight: 700;
}

.loader {
  width: 200px;
  height: 200px;
}

.row {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 24px 36px;
  font-family: 'Source Sans Pro';
  cursor: pointer;
  font-size: 1.2rem;
}

.flexer {
  flex: 2;
  text-align: center;
}

.title {
  flex: 1;
  font-weight: 700;
  font-size: 24px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collaborators {
  composes: flexer;
}

.owner {
  composes: flexer;
}

.time {
  composes: flexer;
}

.createdAt {
  composes: flexer;
}

.path {
  flex: 1;
  text-align: right;
}

.nav {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 88px;
}

.headerTitle {
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.headerEdit {
  width: 40px;
  height: 40px;
  border: 2px solid var(--text-color);
  border-radius: 50px;
  padding: 6px;
  margin: 24px;
}

.placeholder {
  width: 40px;
}

.infosBody {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(4, auto);
  place-items: center;
  align-content: flex-start;
  justify-content: center;
  grid-column-gap: 40px;
  flex: 1;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.enterprise {
  font-size: 20px;
  font-weight: 600;
  width: 350px;
}

.toggler {
  display: flex;
}

.validate {
  padding: 12px;
  padding-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentBody {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr;
  grid-gap: 200px;
  padding: 24px;
  place-content: center;
  place-items: center;
}

.paymentCard {
  border-radius: 20px;
  padding: 38px;
  min-width: 400px;
  box-shadow: var(--box-shadow);
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, auto);
  background: var(--payment-card-color);
  font-size: 20px;
  margin-bottom: 40px;
}

.paymentChip,
.paymentLast4,
.paymentDate {
  grid-column: 1;
}

.paymentChip {
  margin-bottom: 20px;
}

.paymentBrand {
  grid-column: 2;
  grid-row: 1 / 5;
  justify-self: flex-end;
  align-self: flex-end;
}

.addCard {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--background-light);
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  padding: 70px;
  align-items: center;
  justify-content: center;
}

.addCardText {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
}

.addCardPlus {
  border-radius: 40px;
  border: 2px solid var(--text-color);
  padding: 10px;
  margin-top: 20px;
  width: 50px;
  height: 50px;
}

.peopleWrapper {
  overflow: auto;
}

.peopleRow {
  display: flex;
  align-items: center;
  background: var(--background-light);
  margin: 10px 50px;
  padding: 12px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
}

.peopleName {
  margin: 0 12px;
}

.peopleJob {
  flex: 1;
  text-align: center;
}

.peopleCross {
  border: 1px solid var(--text-color);
  border-radius: 300px;
  padding: 3px;
  width: 18px;
  height: 18px;
}

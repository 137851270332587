.socialButton {
  background: var(--background-light);
  border: none;
  display: flex;
  padding: 20px;
  width: 350px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-family: 'Source Sans Pro';
  color: var(--text-color);
  font-size: 20px;
  box-shadow: var(--box-shadow);
  margin: 12px;
  font-weight: 500;
  outline: none;
  text-align: center;
}

.labelInput {
  padding: 0px 12px;
  padding-top: 18px;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 20px;
}

.label {
  display: flex;
  flex-direction: column;
}

.textInput {
  border: none;
  resize: none;
  width: 100%;
  background: transparent;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  text-align: inherit;
}

body:global(.safari) .dateInput {
  width: auto;
}

.textAreaWrapper {
  composes: socialButton;
  flex-direction: column;
  align-items: flex-start;
}

.inputWrapper {
  composes: socialButton;
}

.colorPoint {
  width: 33px;
  height: 33px;
  margin-right: 10px;
  border-radius: 2000px;
  border: 3px solid white;
}

.colorPoint:last-child {
  margin-right: 0;
}

.activePoint {
  cursor: pointer;
  transition: border 0.2s;
  border: 1px solid #a1a1a1;
  border-radius: 20000px;
}

.inactivePoint {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2000px;
}

.colorWrapper {
  composes: inputWrapper;
  align-items: center;
  justify-content: space-between;
}

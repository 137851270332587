.navItem {
  padding: 24px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navItemIcon {
  width: 30px;
  height: 30px;
  margin-bottom: 6px;
}

.navItem:global(.active) {
  background: rgba(207, 207, 207, 0.4);
}

@media (prefers-color-scheme: dark) {
  .navItem {
    border-bottom-color: rgba(20, 20, 20, 1);
  }

  .navItem:global(.active) {
    background: var(--background-light);
  }
}

.card {
  background: var(--background-card);
  flex: 1;
  display: grid;
  grid-template-areas: 'left body';
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-auto-rows: auto;
  overflow: hidden;
}

.edgingColor {
  height: 15px;
  background-color: var(--card-color);
}

.cardWrapper {
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.nav {
  grid-area: left;
  border-right: 2px solid var(--card-color);
}

.body {
  grid-area: body;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.rowBody {
  composes: body;
  flex-direction: row;
}

.flexer {
  display: flex;
  height: 100%;
}

.splitItem {
  flex: 1;
}

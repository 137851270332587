.socialButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-area: body;
}

.layout {
  grid-row: 1 / 4;
  background: var(--background-grey);
  display: grid;
  grid-template-areas:
    'logo     body go2'
    'nothing2 body go2'
    'go3      body nothing'
    'go3      body go1'
    'nothing3 body go1';
}

.go {
  max-height: 200px;
  justify-self: center;
}

.go1 {
  composes: go;
  grid-area: go1;
}

.go2 {
  composes: go;
  grid-area: go2;
  align-self: flex-end;
}

.go3 {
  composes: go;
  grid-area: go3;
  align-self: center;
}

.patate1,
.patate2 {
  grid-column: 1 / 4;
  grid-row: 1 / 6;
}

.patate1 {
  justify-self: flex-end;
  align-self: flex-end;
}

.logo {
  grid-area: logo;
  padding: 24px;
}

.spacer {
  padding: 30px;
}

.title,
.subtitle {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  color: #484848;
  font-size: 50px;
  padding: 16px;
}

@media (prefers-color-scheme: dark) {
  .title,
  .subtitle {
    color: #ccc;
  }
}

.subtitle {
  font-size: 42px;
  font-weight: 400;
}

.labelInput {
  padding: 0px 12px;
  padding-top: 18px;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

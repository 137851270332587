.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.groPlus {
  padding: 1.5rem;
  cursor: pointer;
  text-align: center;
  font-size: 42px;
}

.gridProject {
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 24px;
}

.placeholderTitle {
  text-align: center;
  padding: 36px;
  font-size: 2rem;
  max-width: 550px;
  margin: auto;
  font-weight: 500;
}

.businessMan {
  position: absolute;
  z-index: -1;
  bottom: 30px;
  left: 60px;
}

.patate {
  position: fixed;
  z-index: -2;
  right: 0;
  bottom: 0;
}

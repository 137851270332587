@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

:root {
  --background: #eceff1;
  --background-card: #fafafa;
  --background-grey: #f2f2f2;
  --background-light: #ffffff;
  --background-light-disabled: #f0f0f0;
  --background-light-active: #f5f5f5;
  --text-color: #000000;
  --text-color-disabled: #808080;
  --blue: #22b8da;
  --red: #cf4860;
  --yellow: #f4c54d;
  --cyan: #04e0d0;
  --green: #9cc63d;
  --orange: #d77a51;
  --pink: #e69dbb;
  --purple: #7a7acf;
  --grey: #cccccc;
  --lighten-blue: #90dbec;
  --lighten-red: #e7a3af;
  --lighten-yellow: #f9e2a6;
  --lighten-cyan: #81efe7;
  --lighten-green: #cde29e;
  --lighten-orange: #ebbca8;
  --lighten-pink: #f2cedd;
  --lighten-purple: #bcbce7;
  --darken-blue: #0094c7;
  --darken-red: #b70010;
  --darken-yellow: #eea800;
  --darken-cyan: #00d0b8;
  --darken-green: #6aa900;
  --darken-orange: #c33700;
  --darken-pink: #d96c99;
  --darken-purple: #3737b7;
  --payment-card-color: #e0efff;
  --box-shadow: 0px 3px 2px 0px #a1a1a1;
  --box-shadow-light: 0px 3px 2px 0px #e8e8e8;
  --box-shadow-inset: inset 0px 3px 2px 0px #a1a1a1;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-grey);
  color: var(--text-color);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-card: #202020;
    --background-grey: #24272d;
    --background-light: #303030;
    --background-light-disabled: #1c1c1c;
    --background-light-active: #242424;
    --text-color: #ffffff;
    --text-color-disabled: #9a9a9a;
    --payment-card-color: #509ff0;
    --box-shadow: 0px 3px 2px 0px black;
    --box-shadow-light: 0px 3px 2px 0px rgb(35, 35, 35);
    --box-shadow-inset: inset 0px 3px 2px 0px black;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

button {
  background: transparent;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 1rem;
  color: var(--text-color);
}

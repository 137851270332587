.navbar {
  grid-area: navbar;
  background: #595959;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  position: sticky;
  top: 0px;
}

.body {
  grid-row: 3 / 4;
  display: flex;
  justify-content: center;
}

.spacer {
  flex: 1;
}

.hello {
  font-family: 'Source Sans Pro';
  color: white;
  font-weight: 700;
  font-size: 18px;
  padding-right: 12px;
}

.title {
  grid-row: 2 / span 1;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 24px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  max-width: 1400px;
  width: 100%;
  height: 90px;
  padding: 30px 40px;
  margin: auto;
}

.titleText {
  flex: 1;
  text-align: center;
}

.titlePlaceholder {
  width: 20px;
}

.titlePlaceholder {
  width: 40px;
}

.bodyWrapper {
  max-width: 1400px;
  padding: 0 40px;
  padding-bottom: 40px;
  justify-self: center;
  height: 100%;
  flex: 1;
}

.arrowLeftIcon {
  height: 30px;
  width: 15px;
}

.deleteIcon {
  width: 30px;
  height: 30px;
}

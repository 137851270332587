.header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 88px;
}

.headerTitle {
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.infosBody {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 20px repeat(4, auto) 1fr auto;
  place-items: center;
  align-content: center;
  justify-content: center;
  grid-auto-flow: column;
  grid-column-gap: 100px;
  flex: 1;
}

.infosGirl {
  grid-column: 2;
  grid-row: 2 / 5;
  align-self: flex-start;
}

.toggleArchive {
  grid-column: 1 / 3;
  grid-row: 6;
  display: flex;
  align-items: center;
  padding: 20px;
  align-self: flex-end;
}

.archive {
  font-size: 1.5rem;
  padding-left: 12px;
  font-weight: 600;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.toggler {
  display: flex;
}

.headerEdit {
  width: 40px;
  height: 40px;
  border: 2px solid var(--text-color);
  border-radius: 50px;
  padding: 6px;
  margin: 24px;
}

.plages {
  display: flex;
  flex-direction: row;
}

.plage {
  border-radius: 16px;
  border: solid #979797;
  padding: 0.4rem;
  cursor: pointer;
  margin: 0.2rem;
}

.choosePlage {
  padding: 0 24px;
}

.chart {
  margin: 24px;
  background: white;
  border-radius: 20px;
  box-shadow: var(--box-shadow-light);
}

.collabsInfo {
  margin-top: 60px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  align-items: center;
  overflow: auto;
}

.collabs {
  composes: collabsInfo;
  border-left: 2px solid var(--grey);
}

.informations {
  composes: collabsInfo;
}

.titleCollabsInfo {
  font-weight: 700;
  font-size: 2rem;
}

.createButton {
  grid-column: 1 / 3;
  grid-row: 2;
  justify-self: center;
  padding: 40px;
}

.collabPill {
  flex-shrink: 0;
  border: 1px solid var(--grey);
  border-radius: 10px;
  padding: 2px 6px;
  max-width: calc(100% - 10px);
  font-size: 0.9rem;
  background: var(--background-card);
  display: flex;
  align-items: center;
  margin: 0 4px 4px 0;
}

.collabPillText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.crossIcon {
  margin-left: 6px;
  height: 10px;
  flex-shrink: 0;
  cursor: pointer;
}

.pills {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
}

.peopleWrapper {
  overflow: auto;
}

.peopleRow {
  display: flex;
  align-items: center;
  background: var(--background-light);
  margin: 10px 50px;
  padding: 12px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
}

.peopleJobSection {
  text-transform: capitalize;
  margin: 10px 50px;
  padding-top: 24px;
  padding-bottom: 0px;
  font-weight: 700;
  font-size: 1.2rem;
}

.peopleName {
  margin: 0 12px;
}

.peopleJob {
  flex: 1;
  text-align: center;
}

.peopleCross {
  border: 1px solid var(--text-color);
  border-radius: 300px;
  padding: 3px;
  width: 18px;
  height: 18px;
}

.buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 12px;
}

.archiveModal {
  padding: 48px;
  border-radius: 12px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.archiveTitle {
  padding: 0px 12px;
  font-size: 2rem;
  max-width: 350px;
}

.archiveSubTitle {
  font-size: 1.6rem;
  color: #555;
  padding: 12px;
  padding-bottom: 48px;
  max-width: 350px;
}

.archiveButtons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickOverlay {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
}

.modal {
  align-items: center;
  justify-content: center;
  flex: 1;
}

.child {
  position: absolute;
  z-index: 1000000;
}

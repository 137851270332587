.spacer {
  padding: 30px;
}

.socialButton {
  cursor: pointer;
  background: var(--background-light);
  border: none;
  display: flex;
  padding: 20px;
  width: 350px;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  font-family: 'Source Sans Pro';
  color: var(--text-color);
  font-size: 20px;
  box-shadow: inset 0px 0px 0px 0px transparent, var(--box-shadow);
  margin: 12px;
  font-weight: 500;
  outline: none;
  transition: background 0.5s;
  transition: box-shadow 0.5s;
}

.socialButton:active {
  transition: background 0s;
  transition: box-shadow 0s;
  color: var(--text-color);
  background: var(--background-light-active);
  box-shadow: var(--box-shadow-inset), 0px 0px 0px 0px transparent;
}

.title,
.subtitle {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  color: #484848;
  font-size: 50px;
  padding: 16px;
}

@media (prefers-color-scheme: dark) {
  .title,
  .subtitle {
    color: #ccc;
  }
}

.subtitle {
  font-size: 42px;
  font-weight: 400;
}

.socialIcon {
  width: 30px;
}

.switch {
  width: 60px;
  height: 30px;
  display: flex;
  position: relative;
  border: 2px solid var(--text-color-disabled);
  border-radius: 200px;
  transition: all 0.2s;
  cursor: pointer;
}

.activeSwitch {
  composes: switch;
  background: var(--text-color-disabled);
}

.inactiveSwitch {
  composes: switch;
  background: var(--background-card);
}

.point {
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  background: var(--background-card);
  position: absolute;
  z-index: 10;
  top: 3px;
  left: 3px;
  transition: all 0.2s;
  border: 2px solid var(--text-color-disabled);
}

.inactivePoint {
  composes: point;
}

.activePoint {
  composes: point;
  left: 33px;
}

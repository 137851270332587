.button {
  padding: 12px 36px;
  font-size: 1.5rem;
  border-radius: 20px;
  border: none;
  background: var(--background-light);
  box-shadow: inset 0px 0px 0px 0px transparent, var(--box-shadow);
  color: var(--text-color);
  outline: none;
  cursor: pointer;
  margin: 12px;
  width: 300px;
  font-weight: 600;
  transition: background 0.5s;
  transition: box-shadow 0.5s;
}

.dark {
  color: var(--text-color-disabled);
  background: var(--background-light-disabled);
  box-shadow: var(--box-shadow-inset), 0px 0px 0px 0px transparent;
}

.button:not(:disabled):active {
  transition: background 0s;
  transition: box-shadow 0s;
  color: var(--text-color);
  background: var(--background-light-active);
  box-shadow: var(--box-shadow-inset), 0px 0px 0px 0px transparent;
}

.primary {
  composes: button;
}

.cancel {
  composes: button;
  background: red;
  color: white;
}

.cancel:not(:disabled):active {
  background: rgb(225, 0, 0);
  color: white;
}

.disabled {
  composes: button;
  composes: dark;
  cursor: not-allowed;
}

.small {
  width: auto;
}
